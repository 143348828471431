import React from "react";
import {Armour, Hero, HeroEquipment, Weapon} from "../../global";
import {HeroDetails} from "./components/HeroDetails";
import {HeroAbilities} from "./components/HeroAbilities";
import {HeroEquipmentView} from "./components/HeroEquipment";
import {HeroPerksView} from "./components/HeroPerksView";
import {HeroSkillsView} from "./components/HeroSkillsView";

type HeroViewProps  = {
    removedWeapon?: (hero: Hero, weapon: Weapon) => void;
    isReadonly: boolean;
    hero: Hero
    removedArmour?: (hero: Hero, armour: Armour) => void;
}
export const HeroView = (props: HeroViewProps) => {
    
    const hero = props.hero

    const removedWeapon = (weapon: Weapon, equipment: HeroEquipment) => {
        
        hero.equipment = equipment;
        
        hero.attackRating = equipment.attackRating;

        props.removedWeapon!(hero, weapon);
    }

    const removedArmour = (armour: Armour, equipment: HeroEquipment) => {

        hero.equipment = equipment;
        
        hero.defenceRating = equipment.defenceRating;

        props.removedArmour!(hero, armour);
    }
    
    return <>
        <div className="row">
            <div className="col-6">
                <HeroDetails hero={hero}/>
            </div>

            <div className="col-6">
                <HeroEquipmentView isReadonly={props.isReadonly} removedArmour={removedArmour}
                                   removedWeapon={removedWeapon} equipment={hero.equipment}/>
            </div>

            <div className="col-6">
                <HeroAbilities hero={hero}/>
            </div>

            <div className="col-6">
                <HeroPerksView hero={hero}/>
            </div>

            <div className="col-6">
                <HeroSkillsView hero={hero}/>
            </div>

        </div>
        <br/>
    </>
}