import React, {useState} from 'react'
import {NavLink, useNavigate} from 'react-router-dom'
import {Button} from 'react-bootstrap'
import Moment from 'react-moment'

type Props = {
  battles: [any]
  currentRound: number
  maxRounds: number
}

export function Battles(props: Props) {
  const navigate = useNavigate()

  const [currentRound, setCurrentRound] = useState(props.currentRound)
  
  const nextRound = () => {
    if (currentRound === props.maxRounds) {
      setCurrentRound(1)
    } else {
      setCurrentRound(currentRound + 1)
    }
  }

  const previousRound = () => {
    if (currentRound === 1) {
      setCurrentRound(props.maxRounds)
    } else {
      setCurrentRound(currentRound - 1)
    }
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <h3 className='card-title'>Round: {currentRound}</h3>
          <div className='card-toolbar'>
            <div className='d-flex align-items-center gap-2 gap-lg-3'>
              <button
                type='button'
                className='btn btn-sm btn-primary'
                onClick={() => previousRound()}
              >
                Previous
              </button>
              <button type='button' className='btn btn-sm btn-primary' onClick={() => nextRound()}>
                Next
              </button>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <table className='table table-row-dashed table-row-gray-300 gy-7'>
            <thead>
              <tr className='fw-bolder fs-6 text-gray-800'>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.battles
                .filter((battle) => battle.round === currentRound)
                .map((battle) => (
                  <tr key={battle.battle.id}>
                    <td>{battle.battle.homeGuild.guild.name}</td>
                    <td>
                      {battle.battle.hasPlayed ? (
                        `${battle.battle.homeGuild.score} - ${battle.battle.awayGuild.score}`
                      ) : (
                        <Moment date={battle.battle.kickOff} format='YYYY-MM-DD HH:mm' />
                      )}
                    </td>
                    <td>{battle.battle.awayGuild.guild.name}</td>
                    <td>
                      <NavLink to={`/dashboard/battle/${battle.battle.id}`} className='nav-link'>
                        Details
                      </NavLink>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
