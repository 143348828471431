import React, {useEffect, useState} from "react";
import {ApiClient} from "../services/api";
import {Loading} from "../components/Loading";
import {Battle, Guild} from "../global";
import Moment from "react-moment";
import {NavLink} from "react-router-dom";
import {Card} from "react-bootstrap";
import {ReadOnlyField} from "../ux/ReadOnlyField";

export const GuildPage = () => {
    
    const [guild, setGuild] = useState<Guild>()
    const [upcomingBattles, setUpcomingBattles] = useState<Battle[]>([])
    
    useEffect(() => {
        const fetch = async () => {
            const api = new ApiClient()

            const data = await api.get('guild');

            setGuild(data);
            setUpcomingBattles(await api.get('battle/guild/upcoming'))
        }
        
        fetch()
        
    }, []);
    
    
    if(!guild) return <Loading/>
    
    return <>

        <Card>
            <Card.Header>
                <Card.Title>Details</Card.Title>
            </Card.Header>
            <Card.Body>
                <form>

                    <ReadOnlyField label="Name" value={guild.name}/>
                    <ReadOnlyField label="Coins" value={guild.coins}/>
                </form>
            </Card.Body>
        </Card>
        
        <br/>
        
        <Card>
            <Card.Header>
                <Card.Title>Trophies</Card.Title>
            </Card.Header>
            <Card.Body>
                <table className='table table-row-dashed table-row-gray-300 gy-7'>
                    <thead>
                    <tr className='fw-bolder fs-6 text-gray-800'>
                        <th>Trophy</th>
                        <th>Rank</th>
                    </tr>
                    </thead>
                    <tbody>
                    {guild.trophies
                        .map((trophy) => (
                            <tr key={trophy.name}>
                                <td>{trophy.name}</td>
                                <td>{trophy.rank}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Card.Body>
        </Card>


        <br/>

        <Card>
            <Card.Header>
                <Card.Title>Upcoming Battles</Card.Title>
            </Card.Header>
            <Card.Body>
                <table className='table table-row-dashed table-row-gray-300 gy-7'>
                    <thead>
                    <tr className='fw-bolder fs-6 text-gray-800'>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {upcomingBattles
                        .map((battle) => (
                            <tr key={battle.id}>
                                <td>{battle.homeGuild.guild.name}</td>
                                <td>
                                    {battle.hasPlayed ? (
                                        `${battle.homeGuild.score} - ${battle.awayGuild.score}`
                                    ) : (
                                        <Moment date={battle.kickOff} format='YYYY-MM-DD HH:mm'/>
                                    )}
                                </td>
                                <td>{battle.awayGuild.guild.name}</td>
                                <td>
                                    <NavLink to={`/dashboard/battle/${battle.id}`} className='nav-link'>
                                        Details
                                    </NavLink>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Card.Body>
        </Card>

    </>
}