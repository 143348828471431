import {PageContent} from "../../../ux/Card";
import React from "react";
import {Armour, HeroEquipment, Weapon} from "../../../global";
import {Button} from "../../../layout/Button";

type Props = {
    isReadonly: boolean;
    equipment: HeroEquipment
    removedArmour?: (armour: Armour, equipment: HeroEquipment) => void;
    removedWeapon?: (weapon: Weapon, equipment: HeroEquipment) => void;
}

export const HeroEquipmentView = (props: Props) => {
    
    const equipment = props.equipment;
    
    const removeWeapon = () => {
        
        const weapon = {...equipment.weapon} as Weapon;
        
        equipment.attackRating = 0;
        
        equipment.weapon = undefined;
        
        props.removedWeapon!(weapon, equipment)
    }


    const removeArmour = async (armour: Armour) => {
        // @ts-ignore
        equipment.armour.slots[armour.armourType] = null;
        
        // @ts-ignore
        equipment.defenceRating = Object.entries(hero.armour.slots).reduce((rating, [, armour]: Armour) => {
            if (armour && typeof armour.rating === 'number') {
                return rating + armour.rating;
            }
            return rating;
        }, 0);
        
        props.removedArmour!(armour, equipment)
    }

    return <PageContent title="Equipment" children={
        <form>
            
            <div className="form-group row">
                <label htmlFor="weapon" className="col-sm-2 col-form-label">Weapon</label>
                <div className="col-sm-5">
                    <input type="text" readOnly className="form-control-plaintext" id="weapon"
                           value={equipment.weapon ? equipment.weapon.name : 'None'}/>
                </div>
                {!props.isReadonly &&
                    <div className="col-sm-5">
                        
                        <button type="button" onClick={() => removeWeapon()}
                                className="btn btn-primary btn-sm">Remove</button>
                    </div>}
            </div>


            {Object.entries(equipment.armour.slots).map(([key, armour]: any) => (
                <div className="form-group row">
                    <label htmlFor={key} className="col-sm-2 col-form-label">{key}</label>
                    <div className="col-sm-5">
                        <input type="text" readOnly className="form-control-plaintext" id={key}
                               value={armour ? armour.name : 'None'}/>
                    </div>
                    {!props.isReadonly &&
                        <div className="col-sm-5">
                            <Button onClick={() => removeArmour!(armour)} name="Remove"/>
                        </div>}
                </div>
            ))}
        </form>
    }></PageContent>
}