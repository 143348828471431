import {Button, Card, Container, Form, Spinner} from 'react-bootstrap'
import React, {useEffect, useState} from 'react'
import {
  fetchAuthSession,
} from 'aws-amplify/auth';
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import {ApiClient} from "../services/api";
import {Loading} from "../components/Loading";

type Props = {
  onCreating?: () => void
  onCreated?: (guild: any) => void
}

export function CreateGuild(props: Props) {
  const [isCreatingGuild, setIsCreatingGuild] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  
  const navigate = useNavigate()

  const [guildName, setGuildName] = useState('')


  useEffect(() => {
    const loadData = async () => {
      const api = new ApiClient();

      const response = await api.get("guild/userHasGuild");

      if(response)
      {
        navigate('/dashboard/guild')
      }
      
      setIsLoading(false)
    }

    loadData();

  }, []);
  
  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsCreatingGuild(true)
    
    const api = new ApiClient();

    await api.post('guild', {guildName: guildName});
    
    setIsCreatingGuild(false)

    navigate('/dashboard/guild')
  }

  if(isLoading) return <Loading/>
  
  return (
      <div>
        <Card style={{marginTop: '20px', marginBottom: '20px'}}>
          <Card.Header as='h5'>Create Guild</Card.Header>
          <Card.Body>
            <Container>
              {isCreatingGuild && (
                  <div className='d-flex justify-content-center align-items-center'>
                    <Spinner animation='border' role='status'></Spinner>
                  </div>
              )}

              <Form onSubmit={onSubmitHandler}>
                <Form.Group className='mb-3' controlId='formGuild'>
                  <Form.Label>Guild Name</Form.Label>
                  <Form.Control
                      type='text'
                      placeholder='Enter Guild Name'
                      onChange={(e) => setGuildName(e.target.value)}
                  />
                </Form.Group>
                <Button variant='primary' type='submit'>
                  Submit
                </Button>
              </Form>
            </Container>
          </Card.Body>
        </Card>
      </div>
  )
}
