import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Armour, Hero, Inventory, SpellBook, Weapon} from "../global";
import {ApiClient} from "../services/api";
import {toast} from "react-toastify";
import {Loading} from "../components/Loading";
import {ItemTable} from "../components/ItemTable";
import {PageContent} from "../ux/Card";
import {HeroView} from "../components/hero/HeroView";

export const RosterHeroView = () => {

    const {heroId} = useParams()

    const [hero, setHero] = useState<Hero>()
    const [inventory, setInventory] = useState<Inventory>()

    useEffect(() => {

        const fetch = async () => {
            const client = new ApiClient();

            const data = await client.get(`hero/${heroId}`)

            setHero(data)

            setInventory(await client.get(`inventory`))

        }

        fetch()

    }, []);

    if(!hero || !inventory) return <Loading/>

    const equipWeapon = async (weapon: Weapon) => {

        if(hero.equipment.weapon) {
            inventory.items[weapon.id] = weapon
        }

        hero.equipment.weapon = weapon
        
        hero.attackRating = hero.strength * 1.5 + (hero.equipment.weapon.rating)
        
        delete inventory.items[weapon.id];

        const heroUpdate = {
            ...hero,
        };

        const inventoryUpdate = {
            ...inventory,
        };

        // @ts-ignore
        setHero(heroUpdate);
        // @ts-ignore
        setInventory(inventoryUpdate);

        toast.info(`Equipped ${weapon.name}`);

        const client = new ApiClient();

        await client.post(`hero/weapon/equip`, {
            heroId: hero!.id,
            weaponId: weapon.id
        })
    }

    const equipArmour = async (armour: Armour) => {

        if( hero.equipment.armour.slots[armour.armourType]) {

            const currentArmour = hero.equipment.armour.slots[armour.armourType];

            inventory!.items[currentArmour.id] = currentArmour
        }

        hero.equipment.armour.slots[armour.armourType] = armour;

        // @ts-ignore
        hero.defenceRating = Object.entries(hero.armour.slots).reduce((rating, [, armour]: Armour) => {
            if (armour && typeof armour.rating === 'number') {
                return rating + armour.rating;
            }
            return rating;
        }, 0);



        delete inventory?.items[armour.id];

        const heroUpdate = {
            ...hero,
        };

        const inventoryUpdate = {
            ...inventory,
        };

        // @ts-ignore
        setHero(heroUpdate);
        // @ts-ignore
        setInventory(inventoryUpdate);

        toast.info(`Equipped ${armour.name}`);

        const client = new ApiClient();

        await client.post(`hero/armour/equip`, {
            heroId: hero!.id,
            armourId: armour.id
        })
    }

    const removedArmour = async (hero: Hero, armour: Armour) => {

        // @ts-ignore
        hero!.armour.slots[armour.armourType] = null;

        inventory!.items[armour.id] = armour;

        const heroUpdate = {
            ...hero,
        };

        const inventoryUpdate = {
            ...inventory,
        };

        // @ts-ignore
        setHero(heroUpdate);
        // @ts-ignore
        setInventory(inventoryUpdate);

        toast.info(`Removed ${armour.name}`);

        const client = new ApiClient();

        await client.post(`hero/armour/remove`, {
            heroId: hero!.id,
            armourType: armour.armourType
        })
    }


    const removedWeapon = async (hero: Hero, weapon: Weapon) => {
        setHero({...hero});

        inventory.items[weapon.id] = weapon;
        
        setInventory({...inventory});
        
        toast.info(`Removed ${weapon.name}`);
        
        const client = new ApiClient();

        await client.post(`hero/weapon/remove`, {
            heroId: hero.id,
        })
    }

    const learnSpell = async (spellBook: SpellBook) => {
        hero.spells[spellBook.spell.type] = spellBook.spell;
        
        setHero({...hero})

        toast.info(`Learned ${spellBook.spell.type}`);
        
        const client = new ApiClient();

        await client.post(`hero/spell/learn`, {
            heroId: hero.id,
            bookId: spellBook.id
        })
    }

    return<>
    
        <HeroView hero={hero} removedArmour={removedArmour} isReadonly={false} removedWeapon={removedWeapon}/>

        <PageContent title="Inventory" children={
            <ItemTable items={Object.values(inventory.items)} actions={
                [
                    {name: 'Equip Armour', func: equipArmour, allowedItemTypes: ['Armour']},
                    {name: 'Equip Weapon', func: equipWeapon, allowedItemTypes: ['Weapon']},
                    {name: 'Learn', func: learnSpell, allowedItemTypes: ['SpellBook']}
                ]}/>
        }/>
    
    </>
}