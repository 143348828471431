import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Hero} from "../global";
import {ApiClient} from "../services/api";
import {Loading} from "../components/Loading";
import {HeroView} from "../components/hero/HeroView";

export const TavernHeroView = () => {
    
    const {heroId} = useParams()

    const [hero, setHero] = useState<Hero>()

    useEffect(() => {

        const fetch = async () => {
            const client = new ApiClient();

            const data = await client.get(`hero/${heroId}`)

            setHero(data)
        }

        fetch()

    }, []);
    
    if(!hero) return <Loading/>

    return <HeroView hero={hero} isReadonly={true}/>
}