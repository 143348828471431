import React from "react";


type ButtonProps = {
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
    name: string;
    
}
export const Button = (props: ButtonProps) => {
    return <>
        <button type="button" onClick={props.onClick} className="btn btn-primary btn-sm">{props.name}</button>
    </>
}