import {Button, Card, Table} from "react-bootstrap";
import React from "react";
import {Storage} from "../../global";

export type Actions = {
    name: string
    func: any
}


type Props = {
    storage: Storage
    actions: Actions[]
}

export const StorageView = (props: Props) => {
    
    const storage = props.storage;
    
    return <>
        <Card>
            <Card.Header>
                <Card.Title>Storage</Card.Title>
            </Card.Header>
            <Card.Body>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Item</th>
                        <th>Value</th>
                        <th>Stock</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(storage.items).map(([key, items]) => (

                        <tr key={key}>
                            <td style={{width: '10%'}} className=''>{key}</td>
                            <td>{items.length > 0 ? items[0].value : 0}</td>
                            <td>{items.length}</td>
                            <td>
                                {props.actions.map((action: any) => (
                                     <Button  disabled={items.length === 0} onClick={() => action.func(key)} name={action.name}></Button>
                                ))}                            
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
        
    </>
}