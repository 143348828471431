import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ApiClient} from "../services/api";
import {Card} from "react-bootstrap";
import {Loading} from "../components/Loading";
import {Battles} from "../components/Battles";

export const Cup = () => {

    const {cupId} = useParams()

    const [cup, setCup] = useState<any>()

    useEffect(() => {

        const fetch = async () => {
            const client = new ApiClient();

            const data = await client.get(`cup/${cupId}`)

            setCup(data)

        }

        fetch()

    }, []);

    if(!cup) return <Loading/>

    return <>
        <Card>
            <Card.Header>
                <Card.Title>{cup.name}</Card.Title>
            </Card.Header>
            <Card.Body>
                <Battles battles={cup.battles} currentRound={1} maxRounds={38} />
            </Card.Body>
        </Card>
    </>
}