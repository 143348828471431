import React from "react";

type FieldProps = {
    size?: string | undefined;
    label: string
    value: any
}

export const ReadOnlyField = (props: FieldProps) => {
    return <>
            <div className="form-group row">
                <label htmlFor={props.label} className="col-sm-2 col-form-label">{props.label}</label>
                <div className={`col-sm-${props.size ? props.size : 10}`}>
                    <input type="text" readOnly className="form-control-plaintext" id={props.label}
                           value={props.value}/>
                </div>
            </div>
        </>
}