import React, {useEffect, useState} from "react";
import {ApiClient} from "../services/api";
import {Card, Table} from "react-bootstrap";
import {Loading} from "../components/Loading";
import {NavLink} from "react-router-dom";
import {Roster, RosterHero, Stable} from "../global";
import {Button} from "../layout/Button";
import {toast} from "react-toastify";

export const RosterPage = () => {
    
    const [roster, setRoster] = useState<Roster>()

    useEffect(() => {
        const fetch = async () => {
          
            const api = new ApiClient();
            
            const data = await api.get('roster');
            
            setRoster(data);
            
        }
        
        fetch()
        
    }, []);
    
    
    if(!roster) return <Loading/>

    const addToBattleTeam = async (hero: RosterHero) => {

        if(Object.entries(roster.stable.heroes).filter(([key, hero]: [string, RosterHero]) => hero.isBattleTeam).length >= 6) {
            toast.error(`Battle Team is Full`);
        }
        
        else {
            hero.isBattleTeam = true;

            setRoster({...roster});
            
            toast.info(`${hero.heroId} added to battle team`);

            const client = new ApiClient();

            await client.post('roster/battleteam/add', {
                heroId: hero.heroId
            });   
        }
    };

    const findCaptain = (): RosterHero | undefined => {
        // Iterate over each hero in the stable
        for (const heroId in roster.stable.heroes) {
            const hero = roster.stable.heroes[heroId];

            // Check if this hero is the captain
            if (hero.isCaptain) {
                return hero;
            }
        }

        // If no captain is found, return undefined
        return undefined;
    };

    const removeFromBattleTeam = async (hero: RosterHero) => {
        
        hero.isBattleTeam = false;

        setRoster({...roster});

        toast.info(`${hero.heroId} removed from battle team`);

        const client = new ApiClient();

        await client.post('roster/battleteam/remove', {
            heroId: hero.heroId
        });
    }


    const makeCaptain = async (hero: RosterHero) => {

        const currentCaptain = findCaptain()

        if(currentCaptain) {
            currentCaptain.isCaptain = false;
        }
        
        hero.isCaptain = true;

        setRoster({...roster});

        toast.info(`${hero.heroId} is now the captain`);

        const client = new ApiClient();

        await client.post('roster/makecaptain', {
            heroId: hero.heroId
        }); 
    }

    return <>
      <Card>
          <Card.Header>
              <Card.Title>Roster</Card.Title>
          </Card.Header>
          <Card.Body>
              <Table striped bordered hover>
                  <thead>
                  <tr>
                      <th>Hero</th>
                      <th>Captain</th>
                      <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  {Object.entries(roster.stable.heroes).sort(([, heroA], [, heroB]) => {
                      return Number(heroA.isBattleTeam) - Number(heroB.isBattleTeam);
                      
                  }).reverse().map(([key, hero]) => (
                      <tr key={key} className={hero.isBattleTeam ? "table-success" : "table-primary"}>
                          <td style={{width: '10%'}} className=''>
                              <NavLink to={`/dashboard/roster/hero/${key}`} className='nav-link'>
                                  {key}
                              </NavLink>
                          </td>
                          <td>{hero.isCaptain ? 'Yes' : 'No'}</td>
                          <td>
                              {!hero.isCaptain && <Button onClick={() => makeCaptain(hero)} name="Make Captain"/>}
                              {!hero.isBattleTeam &&
                                  <Button onClick={() => addToBattleTeam(hero)} name="Add to Battle Team"/>}
                              {hero.isBattleTeam &&
                                  <Button onClick={() => removeFromBattleTeam(hero)} name="Remove from Battle Team"/>}
                          </td>
                      </tr>
                  ))}

                  </tbody>
              </Table>

          </Card.Body>
      </Card>

  </>
}