import {Card} from "react-bootstrap";
import React from "react";

export type PageProps = {
    title: string;
    children: any;
    
}

export const PageContent = (props: PageProps) => {
    return <Card>
        <Card.Header>
            <Card.Title>{props.title}</Card.Title>
        </Card.Header>
        <Card.Body>
            {props.children}
        </Card.Body>
    </Card>
}