import {Card} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Guild, Tavern, TavernHero} from "../global";
import {ApiClient} from "../services/api";
import {Loading} from "../components/Loading";
import {NavLink} from "react-router-dom";
import {Button} from "../layout/Button";
import {toast} from "react-toastify";

export const TavernView = () => {
    
    const [tavern, setTavern] = useState<Tavern>();
    const [guild, setGuild] = useState<Guild>();

    useEffect(() => {
        const fetch = async () => {
            const api = new ApiClient();
            
            const tavernResponse = await api.get('tavern');
            
            setTavern(tavernResponse);
            
            const guildResponse = await api.get('guild')
            setGuild(guildResponse);
        }
        
        fetch()
        
    }, []);

    if(!tavern || !guild) return <Loading/>

    const recruit = async (hero: TavernHero) => {
        
        if(guild?.coins >= 500) {
            
            guild.coins = guild.coins - 500;

            setGuild({...guild});

            toast.info(`Recruit ${hero.heroId}`);

            const api = new ApiClient();

            await api.post('guild/recruit', {
                heroId: hero.heroId
            });   
        }
        
        else {
            toast.error(`Not enough coins!`);
        }
    }

    return <>

        <Card>
            <Card.Header>
                <Card.Title>Coins {guild.coins}</Card.Title>
            </Card.Header>
        </Card>
        
        <Card>
            <Card.Header>
                <Card.Title>Tavern</Card.Title>
            </Card.Header>
            <Card.Body>
                <table className='table table-row-dashed table-row-gray-300 gy-7'>
                    <thead>
                    <tr className='fw-bolder fs-6 text-gray-800'>
                        <th>Hero</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>

                    {Object.entries(tavern.heroes)
                        .map(([key, hero]) => (
                        <tr key={key}>
                            <td style={{width: '10%'}} className=''>
                                <NavLink to={`/dashboard/tavern/hero/${key}`} className='nav-link'>
                                    {key}
                                </NavLink>
                            </td>
                            <td>
                                <Button onClick={() => recruit(hero)} name="Recruit"/>
                            </td>
                        </tr>
                        ))}

                    </tbody>
                </table>
            </Card.Body>
        </Card>
    </>
}