import {Link} from "react-router-dom";
import React from "react";

export type SidebarLinkProps = {
    navigationUrl: string;
    title: string;
    
}

export const SidebarLink = (props: SidebarLinkProps) => {
    return <>
        <li className="nav-item">
            <Link className="nav-link d-flex align-items-center gap-2 active"
                  to={props.navigationUrl}>{props.title}</Link>
        </li>
    </>
}