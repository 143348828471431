import {useEffect, useState} from "react";
import {Chest} from "../global";
import {ApiClient} from "../services/api";
import {StorageView} from "../components/items/StorageView";
import {Loading} from "../components/Loading";
import {toast} from "react-toastify";

export const ChestPage = () => {
    
    const [chest, setChest] = useState<Chest>();

    useEffect(() => {
        const fetch = async () => {
            
            const client = new ApiClient();
            
            const data = await client.get('chest');
            
            setChest(data);
            
        }
        
        fetch();
        
    }, []);

    if(!chest) return <Loading/>

    const take = async (itemKey: string) => {

        const item = chest.storage.items[itemKey][0];
        
            chest.storage.items[item.name].shift()

            setChest({...chest})

            toast.info(`Taken ${item.name}`);

            const api = new ApiClient();

            await api.post('guild/takeitem', {
                itemName: item.name
            });
    };
    
    return <>
        
        <StorageView storage={chest?.storage} actions={[{
            name: "Take",
            func: take
        }]}/>
    </>
}