export const guildLinks = [
    { name: "Dashboard", to: `/dashboard/guild`},
    { name: "Roster", to: `/dashboard/roster`},
    { name: "Inventory", to: `/dashboard/inventory`},
    { name: "Repairs", to: `/dashboard/repairs`},
    { name: "Tavern", to: `/dashboard/tavern`},
    { name: "Vendor", to: `/dashboard/vendor`},
    { name: "Chest", to: `/dashboard/chest`},
    { name: "Sponsors", to: `/dashboard/sponsors`},
];

export const seasonLinks = [
    { name: "Leagues",to: `/dashboard/season/leagues`},
    { name: "Leagues Cups",  to: `/dashboard/season/cups`},
    { name: "Championship Groups",  to: `/dashboard/season/championship/groups`},
    { name: "Championship Knockouts",  to: `/dashboard/season/championship/knockouts`},
];