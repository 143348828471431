import React from "react";
import {Link} from "react-router-dom";
import {SidebarLink} from "./SidebarLink";
import { guildLinks, seasonLinks } from "./nav-links";

export const Sidebar = () => {
  return <>
      <div className="sidebar border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary">
          <div className="offcanvas-md offcanvas-end bg-body-tertiary" id="sidebarMenu"
               aria-labelledby="sidebarMenuLabel">
              <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="sidebarMenuLabel">Guild Warden</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                          data-bs-target="#sidebarMenu" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                  <ul className="nav flex-column">
                      {guildLinks.map(x => {
                         return <SidebarLink navigationUrl={x.to} title={x.name}/>
                      })}
                  </ul>

                  <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary text-uppercase">
                      <span>Season</span>
                      <a className="link-secondary" href="#" aria-label="Add a new report">
                          <svg className="bi">
                              <use xlinkHref="#plus-circle"></use>
                          </svg>
                      </a>
                  </h6>
                  <ul className="nav flex-column mb-auto">
                      {seasonLinks.map(x => {
                         return <li className="nav-item">
                              <Link className="nav-link d-flex align-items-center gap-2 active"
                                    to={x.to}>{x.name}</Link>
                          </li>
                      })}
                  </ul>

              </div>
          </div>
      </div>

  </>
}