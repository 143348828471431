import React from 'react'

type Props = {
  guilds: [any]
}

export function Standings(props: Props) {
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <h3 className='card-title'>Standings</h3>
        </div>
        <div className='card-body'>
          <table className='table table-row-dashed table-row-gray-300 gy-7'>
            <thead>
              <tr className='fw-bolder fs-6 text-gray-800'>
                <th>Rank</th>
                <th>Guild</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {props.guilds
                .sort((a: any, b: any) => {
                  return b.points - a.points
                })
                .map((guild, index) => (
                  <tr key={guild.guild.id}>
                    <td>{index + 1}</td>
                    <td>{guild.guild.name}</td>
                    <td>{guild.points}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
