import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {Amplify} from 'aws-amplify'
import 'bootstrap/dist/css/bootstrap.min.css';
import './dashboard.css'
import {App} from "./App";



Amplify.configure({
    
    auth: {
        user_pool_client_id: process.env.REACT_APP_CLIENT_ID!,
        oauth: {
            domain: process.env.REACT_APP_USER_POOL_DOMAIN!,
            scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirect_sign_out_uri: [process.env.REACT_APP_USER_POOL_REDIRECT_SIGN_IN!],
            redirect_sign_in_uri: [process.env.REACT_APP_USER_POOL_REDIRECT_SIGN_IN!],
            response_type: 'code',
            identity_providers: []
        },
        aws_region: "eu-west-1",
        user_pool_id:  process.env.REACT_APP_USER_POOL!,
    },
    
    Auth: {
        Cognito: {
            userPoolClientId:  process.env.REACT_APP_CLIENT_ID!,
            userPoolId:  process.env.REACT_APP_USER_POOL!,
            loginWith: { // Optional
                oauth: {
                    domain: process.env.REACT_APP_USER_POOL_DOMAIN!,
                    scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                    redirectSignIn: [process.env.REACT_APP_USER_POOL_REDIRECT_SIGN_IN!],
                    redirectSignOut: [process.env.REACT_APP_USER_POOL_REDIRECT_SIGN_IN!],
                    responseType: 'code',
                } 
            }
        }
    }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <App/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
