import "@aws-amplify/ui-react/styles.css";
import React, {useEffect, useState} from 'react';
import {ApiClient} from "../services/api";
import {Card, Table} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {Loading} from "../components/Loading";
import {Championship, ChampionshipGroup, Cup} from "../global";
import {Battles} from "../components/Battles";

const ChampionshipKnockoutsView = () => {

    const [championship, setChampionship] = useState<Championship>();
    const [cup, setCup] = useState<any>();


    useEffect(() => {
        const fetch = async () => {
            const api = new ApiClient()

            const season = await api.get('season')

            const championshipResponse = await api.get(`championship/${season.championshipId}`);
            setChampionship(championshipResponse);


            const cupResponse = await api.get(`cup/${championshipResponse.cup.id}`);
            setCup(cupResponse);
        }

        fetch()

    }, []);

    if(!championship || !cup) return <Loading/>

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>{cup.name}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Battles battles={cup.battles} currentRound={1} maxRounds={38} />
                </Card.Body>
            </Card>
        </>

    )
}

export default ChampionshipKnockoutsView;
