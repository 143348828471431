import {Hero} from "../../../global";
import {PageContent} from "../../../ux/Card";
import React from "react";

type Props = {
    hero: Hero;
}


export const HeroSkillsView = (props: Props) => {

    const skills = props.hero.skills;

    return <PageContent title="Perks" children={
        <form>

            {Object.entries(skills).map(([key, skill]) => (
                <div className="form-group row">
                    <label htmlFor={key} className="col-sm-2 col-form-label">{key}</label>
                    <div className="col-sm-5">
                        <input type="text" readOnly className="form-control-plaintext" id={key}
                               value={skill.level}/>
                    </div>
                </div>
            ))}
        </form>
    }></PageContent>
}