import {PageContent} from "../../../ux/Card";
import React from "react";
import {Hero} from "../../../global";

type Props = {
    hero: Hero;
}

export const HeroPerksView = (props: Props) => {
    
    const perks = props.hero.perks
    
    return <PageContent title="Perks" children={
        <form>

            {Object.entries(perks).map(([key, perk]) => (
                <div className="form-group row">
                    <label htmlFor={key} className="col-sm-2 col-form-label">{key}</label>
                    <div className="col-sm-5">
                        <input type="text" readOnly className="form-control-plaintext" id={key}
                               value={perk.type}/>
                    </div>
                </div>
            ))}
        </form>
    }></PageContent>
}