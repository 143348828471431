import {Hero} from "../../../global";
import React from "react";
import {PageContent} from "../../../ux/Card";


type Props = {
    hero: Hero
}
export const HeroAbilities = (props: Props) => {
    
    const hero = props.hero;
    
    return <>

        <PageContent title="Abilities" children={
            <form>
                {Object.entries(hero.spells).map(([key]) => (
                    <div className="form-group row">
                        <label htmlFor={key} className="col-sm-2 col-form-label">{key}</label>
                    </div>
                ))}
            </form>
        }></PageContent>
        
    </>
}