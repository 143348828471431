import "@aws-amplify/ui-react/styles.css";
import React from 'react';
import {Login} from "@wradetech/react-components";

const LandingView = () => {
    
    return (
        <>
            <Login text="Build. Recruit. Battle" title="Guild Warden"/>
        </>

    )
}

export default LandingView;
