import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Battle, BattleHero, Hero} from "../global";
import {Loading} from "../components/Loading";
import {ApiClient} from "../services/api";
import {Card, Table} from "react-bootstrap";
import {PageContent} from "../ux/Card";

export const BattlePage = () => {

    const {battleId} = useParams()

    const [battle, setBattle] = useState<Battle>()

    useEffect(() => {
        const fetch = async () => {
            const client = new ApiClient();
            
            const data = await client.get(`battle/${battleId}`);
            
            setBattle(data);
        }
        
        fetch()
        
    }, []);
    
    
    if(!battle) return <Loading/>
    
    return <>

        <Card>
            <Card.Header>
                <Card.Title>{battle.homeGuild.guild.name} {battle.homeGuild.fullTimeScore} | {battle.awayGuild.fullTimeScore} {battle.awayGuild.guild.name}</Card.Title>
            </Card.Header>
        </Card>

        <br/>

        <PageContent title={battle.homeGuild.guild.name} children={
            <>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Hero</th>
                        <th>Rating</th>
                    </tr>
                    </thead>
                    <tbody>

                    {battle.homeGuild.heroes?.map((hero: BattleHero) => (
                        <tr key={hero.heroId}>
                            <td style={{width: '10%'}} className=''>{hero.heroId}</td>
                            <td style={{width: '10%'}} className=''>{hero.defenceRating}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
        }></PageContent>

        <br/>
        
        <PageContent title={battle.awayGuild.guild.name} children={
            <>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Hero</th>
                        <th>Rating</th>
                    </tr>
                    </thead>
                    <tbody>

                    {battle.awayGuild.heroes?.map((hero: BattleHero) => (
                        <tr key={hero.heroId}>
                            <td style={{width: '10%'}} className=''>{hero.heroId}</td>
                            <td style={{width: '10%'}} className=''>{hero.defenceRating}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
        }></PageContent>
    </>
}