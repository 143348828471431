import React, {useEffect, useState} from "react";
import {Repair} from "../global";
import {ApiClient} from "../services/api";
import {Loading} from "../components/Loading";
import {Table} from "react-bootstrap";
import {NavLink} from "react-router-dom";

export const RepairsPage = () => {
    
    const [repairs, setRepairs] = useState<Repair[]>()

    useEffect(() => {
        const fetch = async () => {
            const client = new ApiClient()
            
            const data = await client.get('blacksmith/repairs')
            
            setRepairs(data)
        }
        
        fetch();
        
    }, []);

    if(!repairs) return <Loading/>

    return <>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Item</th>
                <th>Status</th>
                <th>Complete Date</th>
            </tr>
            </thead>
            <tbody>
                {repairs.map((repair) => (
                    <tr key={repair.id}>
                        <td>{repair.equipment.name}</td>
                        <td>{repair.status}</td>
                        <td>{repair.completeDate}</td>
                    </tr>
                ))}
            </tbody>

    </Table>
</>
}