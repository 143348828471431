import React, {useEffect, useState} from "react";
import {Guild, Item, Vendor} from "../global";
import {ApiClient} from "../services/api";
import {Loading} from "../components/Loading";
import {Button, Card, Table} from "react-bootstrap";
import {toast} from "react-toastify";
import {StorageView} from "../components/items/StorageView";

export const VendorView = () => {
    
    const [vendor, setVendor] = useState<Vendor>()
    const [guild, setGuild] = useState<Guild>();

    useEffect(() => {
        const fetch = async () => {
            const client = new ApiClient()
            
            const vendorResponse = await client.get('vendor');
            
            setVendor(vendorResponse);

            const guildResponse = await client.get('guild')
            setGuild(guildResponse);
        }
        
        fetch()
        
    }, []);
    
    if(!vendor || !guild) return <Loading/>

    const buy = async (itemKey: string) => {
        
        const item = vendor.storage.items[itemKey][0];
        
        if(guild?.coins >= item.value) {

            guild.coins = guild.coins - item.value;

            setGuild({...guild});
            
            vendor.storage.items[item.name].shift()
            
            setVendor({...vendor})

            toast.info(`Bought ${item.name}`);

            const api = new ApiClient();

            await api.post('guild/buyitem', {
                itemName: item.name
            });
        }

        else {
            toast.error(`Not enough coins!`);
        }
    };

    return <>
        <StorageView storage={vendor.storage} actions={[{
            name: "Purchase",
            func: buy
        }]}/>
    </>
}