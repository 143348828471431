import React, {useEffect, useState} from "react";
import {Sidebar} from "./Sidebar";
import {Content} from "./Content";
import {Header} from "@wradetech/react-components";
import {Amplify} from "aws-amplify";
import {
    signOut,
    fetchUserAttributes
} from 'aws-amplify/auth';
import { guildLinks, seasonLinks } from "./nav-links";

export const MasterLayout = () => {
    
    const [username, setUsername] = useState<string>();
    
    useEffect(() => {

        console.log("Amplify Configuration in Shared Header:", Amplify.getConfig());

        getLoggedInUser();

    }, []);

    const getLoggedInUser = async () => {
        const attributesOutput = await fetchUserAttributes();

        const username = attributesOutput.email;

        setUsername(username)
    }

    const logout = async () => {
        await signOut()
    }
    
    return <>
        
        <Header header={"Guild Warden"} userName={username!} logout={logout} links={guildLinks.concat(seasonLinks)}/>

        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <Content/>
            </div>
        </div>


    </>
}