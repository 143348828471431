import "@aws-amplify/ui-react/styles.css";
import React, {useEffect, useState} from 'react';
import {ApiClient} from "../services/api";
import {Card, Table} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {Loading} from "../components/Loading";
import {ChampionshipGroup} from "../global";

const ChampionshipGroupsView = () => {
    
    const [groups, setGroups] = useState<ChampionshipGroup[]>();
    

    useEffect(() => {
        const fetch = async () => {
            const api = new ApiClient()

            const season = await api.get('season')
            
            const data = await api.get(`championship/${season.championshipId}/groups`);
 
            setGroups(data);
            
            console.log(groups)
        }

        fetch()

    }, []);

    if(!groups) return <Loading/>
    
    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>Season Leagues</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>League</th>
                        </tr>
                        </thead>
                        <tbody>
                        {groups.map((group: ChampionshipGroup) => (
                            <tr key={group.id}>
                                <td style={{width: '10%'}} className=''>
                                    <NavLink to={`/dashboard/league/${group.league.id}`} className='nav-link'>
                                        Group
                                    </NavLink>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                </Card.Body>
            </Card>           
        </>

    )
}

export default ChampionshipGroupsView;
