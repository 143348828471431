import {NavLink, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ApiClient} from "../services/api";
import {Card, Table} from "react-bootstrap";
import {Loading} from "../components/Loading";
import {Standings} from "../components/Standings";
import {Battles} from "../components/Battles";

export const League = () => {

    const {leagueId} = useParams()

    const [league, setLeague] = useState<any>()

    useEffect(() => {
        
        const fetch = async () => {
            const client = new ApiClient();

            const data = await client.get(`league/${leagueId}`)

            setLeague(data)

        }
        
        fetch()
        
    }, []);
    
    if(!league) return <Loading/>
    
    return <>
        <Card>
            <Card.Header>
                <Card.Title>{league.name}</Card.Title>
            </Card.Header>
            <Card.Body>
                
                <Standings guilds={league.guilds}/>
                <Battles battles={league.battles} currentRound={1} maxRounds={38} />
            </Card.Body>
        </Card>
    </>


}