import React, {useEffect, useState} from "react";
import {Guild, Sponsor} from "../global";
import {ApiClient} from "../services/api";
import {Loading} from "../components/Loading";
import {Card, Table} from "react-bootstrap";
import {toast} from "react-toastify";
import {Button} from "../layout/Button";
import {ReadOnlyField} from "../ux/ReadOnlyField";

export const SponsorPage = () => {
    
    const [sponsors, setSponsors] = useState<Sponsor[]>();
    const [guild, setGuild] = useState<Guild>();


    useEffect(() => {
        const fetch = async () => {
            const client = new ApiClient();

            const sponsorsData = await client.get('sponsor');
            const guildData = await client.get('guild');
            
            setSponsors(sponsorsData);
            setGuild(guildData);
        }
        
        fetch()
        
    }, []);
    
    if(!sponsors || !guild) return <Loading/>

    const chooseSponsor = async (sponsor: Sponsor) => {

        toast.info(`Choosen ${sponsor.name}`);

        const client = new ApiClient();

        await client.post('guild/choosesponsor', {
            sponsorId: sponsor.id
        });
    }

    const getCurrentSponsor = () : Sponsor => {
        return sponsors.filter(x => x.id === guild.sponsorId)[0];
    }
    
    
    return <>

        <Card>
            <Card.Header>
                <Card.Title>Current Sponsor</Card.Title>
            </Card.Header>
            <Card.Body>
                <form>
                    <ReadOnlyField label="Name" value={guild.sponsorId ? getCurrentSponsor().name : 'None'}/>
                </form>
            </Card.Body>
        </Card>

        <Card>
            <Card.Header>
                <Card.Title>Sponsors</Card.Title>
            </Card.Header>
            <Card.Body>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Coin Reward</th>
                        <th>Item Reward</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {sponsors.map((sponsor) => (
                        <tr key={sponsor.id}>
                            <td style={{width: '10%'}} className=''>{sponsor.id}</td>
                            <td style={{width: '10%'}} className=''>{sponsor.name}</td>
                            <td style={{width: '10%'}} className=''>{sponsor.coinsRewards}</td>
                            <td style={{width: '10%'}} className=''>{sponsor.itemTypeReward ? sponsor.itemTypeReward : 'None'}</td>
                            <td style={{width: '10%'}} className=''>
                                <Button onClick={() => chooseSponsor(sponsor)} name="Pick"/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
        
        
    </>
}